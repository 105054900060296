import React, { ReactNode, useState } from "react";
import IconClose from "../Icon/IconClose";
import { useIsDesktop } from "lib/device";
import { motion } from "framer-motion";
import { HomeLogo } from "modules/Home/HomeLogo";

const useOutsideClick = (callback: () => void) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !(ref.current as any)?.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
};

export type Props = {
  isShow?: boolean;
  isFullScreen?: boolean;
  contentStyles?: object;
  contentWidth?: number;
  className?: string;
  contentClassName?: string;
  zIndex?: number;
  title?: string | ReactNode;
  onClose?(): void;
  onClickOutside?(): void;
  children: ReactNode;
  childrenClass?: string;
  position?: string;
};

const Drawer: React.FC<Props> = ({
  isShow,
  isFullScreen,
  className,
  contentClassName,
  zIndex,
  contentStyles,
  contentWidth,
  title,
  onClose,
  onClickOutside,
  children,
  childrenClass,
  position = "right",
}) => {
  const isDesktop = useIsDesktop();

  const ref = useOutsideClick(() => {
    if (onClickOutside) {
      onClickOutside();
    }
  });

  const classPosition = {
    right: ["w-full top-0 right-0 justify-end", "w-[25vw] 2xl-max:w-[35vw] rounded-l-16"],
    bottom: ["inset-0 w-[100vw] justify-center", "w-[100vw] rounded-t-16"],
    top: ["inset-0 w-[100vw] justify-start", "w-[100vw] rounded-b-16"],
  };

  if (isShow) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={isShow ?
          { opacity: 1 } :
          { opacity: 0 }
        }
        style={{ zIndex: zIndex || 9999, position: "relative" }}
      >
        <div
          className={`fixed ${classPosition[position][0]} flex ${position == "top"
              ? "items-start"
              : "items-end"
            } bg-[#00000080] ${className || ""}`}
          style={{ zIndex: zIndex || 9999 }}
        >
          <div
            ref={ref}
            className={`relative bg-white py-[18px] p-[9px] ${classPosition[position][1]
              } overflow-auto ${isFullScreen ? `h-screen ` : ""} ${contentClassName || ""
              }`}
            style={{
              ...contentStyles,
            }}
          >
            {(title || onClose) && (
              <div className={`flex items-center pb-16`}>
                <HomeLogo />
                {onClose && (
                  <button type="button" className="ml-auto" onClick={onClose}>
                    <IconClose size={20} color="#000000" />
                  </button>
                )}
              </div>
            )}
            <div className={childrenClass ?? ""}>{children}</div>
          </div>
        </div>

        <style>{`
          html, body {
            overflow: hidden;
          }
        `}</style>
      </motion.div>
    );
  }
  return null;
};

export default Drawer;
